import React, { memo } from "react";

const EmailList = memo(({ emails }) => {
  return (
    <div>
      <ul>
        {emails.length > 0 &&
          emails.map((item, index) => <li key={index}>{item}</li>)}
      </ul>
    </div>
  );
});

export default EmailList;
