import { useState } from "react";
import axios from "axios";
import EmailList from "./email-list/EmailList";
import "./email.css";

const Email = () => {
  const [email, setEmail] = useState("");
  const [emails, setEmails] = useState([]);

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleClick();
    }
  };

  const handleClick = () => {
    setEmails([...emails, email]);
    setEmail("");
  };

  const handleSend = async () => {
    const result = await axios.post(
      `${process.env.REACT_APP_ASSESSMENT_SERVICE_BASE_URL}/email`,
      {
        emails,
      }
    );
    console.log(result.data);
  };

  return (
    <section id="emailbox">
      <div className="email">
        <EmailList emails={emails} />
        <input
          type="email"
          placeholder="Enter email id here"
          value={email}
          onKeyDown={handleKeyDown}
          onChange={handleChange}
        />
        <button onClick={handleSend}>Send Email</button>
      </div>
    </section>
  );
};

export default Email;
