import React, { useEffect, useState } from "react";
import Navbar from "../../layout/header/Header";
import "./accessManagement.scss";
import { db } from "../../../database";
import Loading from "../../layout/loader/Loading";
import { toast } from "react-toastify";
import axiosInstance from "../../../httpInterceptor";
const AccessManagement = () => {
  const [input, setInput] = useState("");
  const [flag, setFlag] = useState(true);
  const [selectedList, setSelectedDetails] = useState({
    psno: "",
    email: "",
    firstName: "", // first name
    lastName: "", // last name
  });
  const [showDetails, setShowDetails] = useState(false);
  const [currRole, setCurrRole] = useState<any>("No Role");
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [updateRole, setUpdateRole] = useState({
    psNo: "",
    role: "",
  });
  const [psNo, setPsNo] = useState("");
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  // console.log(PsNo);
  //   const fetchData=(value: string)=>{
  //     const result= db.nameList.filter((item, index)=>{
  //       return value && item &&item.name && item.name.toLocaleLowerCase().includes(value);
  //     })
  //     setUserList(result);
  //     setFlag(true)
  // }

  // const [roles, setRoles] = useState([""]);
  // useEffect(() => {
  //   const res = db.role;
  //   console.log(res);
  //   setRoles(db.role);
  // }, []);
  const getRole = async (PsNo: any) => {
    try {
      const res = await axiosInstance.get(
        `${process.env.REACT_APP_USER_SERVICE_BASE_URL}/getUserRole/?PsNo=${PsNo}`
      );
      if (res) {
        setCurrRole(res.data);
      }
    } catch (error) {
      if (error) {
        console.log("Error fetching the data", error);
      }
    }
  };
  useEffect(() => {
    if (psNo.length > 0) getRole(psNo);
  }, [psNo]);

  useEffect(() => {
    setIsSaveDisabled(updateRole.role === "" || updateRole.role === currRole);
  }, [updateRole, currRole]);

  // const fetchUsers = async (search: string) => {
  //   try {
  //     const res = await axios.get(
  //       `http://localhost:4001/api/getPSNumber/?search=${search}`
  //     );
  //     const users = res.data.value.length > 0 ? res.data.value : [];
  //     console.log(users);

  //     setShowDetails(res.data);
  //   // return value && item &&item.name && item.name.toLocaleLowerCase().includes(value);
  //   } catch (error) {
  //     console.error("Error fetching the data", error);
  //   }
  // };
  const fetchSuggestions = async (search: string) => {
    if (!search) return;

    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_USER_SERVICE_BASE_URL}/getPSNumber/?search=${search}`
      );
      setSuggestions(response.data); // Adjust according to your API response structure
    } catch (error) {
      console.error("Error fetching suggestions:", error);
    }
  };

  const handleChange = (value: string) => {
    setInput(value);
    setFlag(true);
    setSuggestions([]);
    setShowDetails(false);
    setCurrRole("No Role");
    setUpdateRole({ psNo: "", role: "" });
    fetchSuggestions(value);
    if (value == "") {
      setFlag(false);
    }
  };

  const handleUpdateRole = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setUpdateRole({ ...updateRole, [name]: value, psNo: psNo });
  };
  const handleOnClick = (value: any) => {
    // console.log(value);
    setFlag(true);
    setSelectedDetails(value);
    setPsNo(value.psno);
    setShowDetails(true);
    setInput("");
    setSuggestions([]);
    setLoading(false);
  };

  const handleSaveRole = async () => {
    const fullName = `${selectedList.firstName} ${selectedList.lastName}`;
    console.log(psNo, updateRole, selectedList.email);

    try {
      const NewRole = {
        name: fullName,
        email: selectedList.email,
        psNo: psNo,
        role: updateRole.role,
      };
      const SaveRole = await axiosInstance.post(
        `${process.env.REACT_APP_USER_SERVICE_BASE_URL}/updateUserRole`,
        NewRole
      );
      setCurrRole(SaveRole.data.role);
      return SaveRole ? toast.success("Role Updated successfully") : "";
    } catch (error) {
      console.log("Error saving the role", error);
    }
  };

  return (
    <div className="accessManagementContainer">
      <Navbar />
      <div className="app-pageSize">
        <div className="app-pageHeader searchHeader">
          <input
            className="searchBar"
            type="text"
            placeholder="Search..."
            autoCapitalize="words"
            value={input}
            onChange={(e) => handleChange(e.target.value)}
          />
          <img src={require("../../../images/search.png")} alt="" />
        </div>
        {flag && (
          <div className={suggestions.length > 0 ? "showResult" : ""}>
            {suggestions.length > 0 &&
              suggestions.map(
                (result: { name: string; jobtitle: string }, idx) => {
                  return (
                    <div
                      key={idx}
                      style={{
                        display: "grid",
                        // justifyContent: "space-between",
                        cursor: "pointer",
                      }}
                      onClick={() => handleOnClick(result)}
                    >
                      <span> {result.name}</span>
                      <span> {result.jobtitle}</span> <br />
                    </div>
                  );
                }
              )}
          </div>
        )}
        {showDetails && (
          <div className="userDetailsContainer">
            <div className="userdetailHeader">User Details</div>

            <div className="userDetails">
              <div className="column">
                <div className="form-content">
                  <label>First Name:</label>
                  <span>{selectedList.firstName}</span>
                </div>
                <div className="form-content">
                  <label>Email ID:</label>
                  <span>{selectedList.email}</span>
                </div>
                <div className="form-content">
                  <label>Current Role:</label>
                  <span>{currRole}</span>
                </div>
              </div>
              <div className="column-2">
                <div className="form-content">
                  <label>Last Name:</label>
                  <span>{selectedList.lastName}</span>
                </div>
                <div className="form-content">
                  <label>PS No:</label>
                  <span>{selectedList.psno}</span>
                </div>
                <div>
                  <div className="form-content">
                    <label>Update Role:</label>
                    <select
                      onChange={handleUpdateRole}
                      name="role"
                      className="options"
                    >
                      <option style={{ display: "none" }}>
                        Select the option
                      </option>
                      {db.role &&
                        db.role.map((res: any) => (
                          <option key={res}>{res}</option>
                        ))}
                    </select>
                  </div>
                  <button
                    className="saveButton"
                    type="button"
                    onClick={handleSaveRole}
                    disabled={isSaveDisabled}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AccessManagement;
// email: "Karuna.Bisen@ltimindtree.com";
// firstName: "Karuna";
// lastName: "Bisen";
// name: "Karuna Bisen (IN93791)";
// psno: "61093791";
